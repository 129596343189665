const validateCheckboxes = function() {
  ['1','2','3'].forEach(function(number) {
    if (!$('#tos_agreement_' + number + '_checkbox').is(':checked')) {
      event.preventDefault();
      $('#tos_agreement_' + number + '_label').addClass('invalid');
      $('#tos_agreement_' + number + '_checkbox').addClass('invalid');
    }
  });
};

$(function() {
  $('#user_agreement').on('click', function(event) {
    validateCheckboxes();
    if (!$('#i_agree').is(':checked')) {
      event.preventDefault();
      $('#i_agree_label span').addClass('invalid');
      $('#i_agree').addClass('invalid');
    }
  });

  $('#registration-step-1').on('submit', function(event) {
    validateCheckboxes();
    if (!$('#customer_account_agreement_accepted').is(':checked')) {
      $('#agreement_accepted label').addClass('red');
    }
  });

  $('#new_customer_account').on('submit', function(event) {
    validateCheckboxes();
    if (!$('#customer_account_agreement_accepted').is(':checked')) {
      $('#agreement_accepted label').addClass('red');
    }
  });

  $('#new_card_detail').on('submit', function(event) {
    validateCheckboxes();
  });

  ['1','2','3'].forEach(function(number) {
    $('#tos_agreement_' + number).on('click', function() {
      if ($('#tos_agreement_' + number + '_checkbox').is(':checked')) {
        if ($(".checkbox input:checked").length === 3) {
          $("#new_card_detail input").prop("disabled", false);
        }
        $('#tos_agreement_' + number + '_label').removeClass('invalid');
        $('#tos_agreement_' + number + '_checkbox').removeClass('invalid');
      }
    });
  });

  $('#i_agree_label').on('click', function() {
    if ($('#i_agree').is(':checked')) {
      $('#i_agree_label span').removeClass('invalid');
      $('#i_agree').removeClass('invalid');
    }
  });

  $('#agreement_accepted').on('click', function() {
    if ($('#customer_account_agreement_accepted').is(':checked')) {
      $('#agreement_accepted label').removeClass('red');
    }
  });
});
