/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export default class LeadMap {
  constructor(lat, lng, zoom){
    this.zoom = zoom ? zoom : 8;
    this.lat  = lat ? lat : 40.017;
    this.lng  = lng ? lng : -105.28;
    this.markersArray  = [];
    this.radiusCircles = [];
    this.polygons = [];
    this.map  = null;
    this.toggleMarkersController = null;
  }

  initMap() {
    this.map_div = $('#map');
    this.map_div_node  = this.map_div[0];
    if (this.map_div.size() > 0) {
      this.map = new google.maps.Map(this.map_div_node, {
        center: new google.maps.LatLng(this.lat, this.lng),
        zoom: this.zoom,
        fullscreenControl: true
      });
    }
    if (this.toggleMarkersController) {
      this.map.controls[google.maps.ControlPosition.TOP_RIGHT]
           .push(this.toggleMarkersController.element[0]);

      const controller = this.toggleMarkersController;
      this.map.addListener('idle', function() {
        controller.setInitialVisibility();
        controller.show();
      });
    }
  }

  initTable(total_count){
    this.resultsTable = $('#results-table');
    this.allLeads = $('#all-leads');
    this.fourWeeksLeads = $('#four-week-leads');
    this.oneWeekLeads = $('#one-week-leads');

    this.allLeads.html(total_count.toLocaleString('en-US'));
    this.fourWeeksLeads.html(Math.floor(total_count/3).toLocaleString('en-US'));
    this.oneWeekLeads.html(Math.floor(total_count/12).toLocaleString('en-US'));
    this.resultsTable.removeClass('hidden');
  }

  updateZoom(swCorner, neCorner){
    if ((swCorner !== undefined) && (neCorner !== undefined)) {
      let new_zoom = undefined;
      const update_zoom = ((swCorner.lat === neCorner.lat) && (swCorner.lng === neCorner.lng));

      if (update_zoom) {
        new_zoom = this.map.getZoom();
      }

      const bounds = new google.maps.LatLngBounds(swCorner, neCorner);
      this.map.fitBounds(bounds);

      if (update_zoom) {
        this.map.setZoom(new_zoom + 1);
      }
    }
  }

  zoomOutUsa() {
    const SW_LAT_USA = 35.037144;
    const SW_LNG_USA = -113.210601;
    const NE_LAT_USA = 42.190432;
    const NE_LNG_USA = -78.716099;
    const swCorner = new google.maps.LatLng(SW_LAT_USA, SW_LNG_USA);
    const neCorner = new google.maps.LatLng(NE_LAT_USA, NE_LNG_USA);
    this.updateZoom(swCorner, neCorner);
  }

  updateZoomRadius(circle){
    this.map.fitBounds(circle.getBounds());
  }

  addMarker(lat, lng){
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      map: this.map
    });
    this.markersArray.push(marker);
  }

  removeAllMarkers() {
    for (let marker of Array.from(this.markersArray)) {
      marker.setMap(null);
    }
    this.markersArray = [];
  }

  addMarkersToggleController(elem){
    this.toggleMarkersController = new LeadToggleMarkers(elem, this);
  }

  toogleVisibleMarkers() {
    Array.from(this.markersArray).map((marker) =>
      !marker.getVisible() ? marker.setVisible(true) : marker.setVisible(false));
  }

  hideMarkers() {
    Array.from(this.markersArray).map((marker) =>
      marker.setVisible(false));
  }

  showMarkers() {
    Array.from(this.markersArray).map((marker) =>
      marker.setVisible(true));
  }

  drawRadius(radius){
    const circle = new google.maps.Circle(radius.radiusSettings());
    this.updateZoomRadius(circle);
    this.removeAllRadii();
    this.radiusCircles.push(circle);
  }

  removeAllRadii() {
    for (let radius of Array.from(this.radiusCircles)) {
      radius.setMap(null);
    }
    this.radiusCircles = [];
  }

  hideOverlayText() {
    //hide map overlay text
    $('.no-leads-div').css("display", "none");
  }

  drawCounties(countiesGeometry) {
    this.removeAllPolygons();
    Array.from(countiesGeometry).map((countyGeometry) =>
      this.drawPolygons(countyGeometry));
  }

  drawZipCodes(zipCodeGeometries) {
    this.removeAllPolygons();
    Array.from(zipCodeGeometries).map((zipCodeGeometry) =>
      this.drawPolygons(zipCodeGeometry));
  }

  drawPolygons(polygons){
    (() => {
      const result = [];
      for (let polygon of Array.from(polygons)) {
        const new_polygon = new google.maps.Polygon({
          paths: polygon,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35
        });
        new_polygon.setMap(this.map);
        result.push(this.polygons.push(new_polygon));
      }
      return result;
    })();
  }

  removeAllPolygons() {
    Array.from(this.polygons).map((polygon) =>
      polygon.setMap(null));
  }

  requestAndDrawCounties(fipsCodesString, keepRadii) {
    if (keepRadii == null) { keepRadii = ''; }
    if (keepRadii !== 'keepRadii') {
      window.leadMap.zoomOutUsa();
    }
    const fipsCodes = fipsCodesString.split(", ");
    delay((function() {
      let ajaxQuery = "";
      for (let code of Array.from(fipsCodes)) {
        ajaxQuery += 'selected[]=' + code + '&';
      }
      const url = '/api/v2/fips.json?' + ajaxQuery;
      $(".cities-loading").show(500);
      $.ajax({
        url,
        async: false,
        success(response) {
          const countiesGeometry = LeadForm.sanitizeCountiesGeometry(response);
          if (keepRadii !== 'keepRadii') {
            window.leadMap.removeAllRadii();
          }
          window.leadMap.drawCounties(countiesGeometry);
        },
        error(response) {
          if (response.status === 404) {
            window.leadMap.removeAllPolygons();
            if (keepRadii !== 'keepRadii') {
              window.leadMap.removeAllRadii();
            }
          }
        },
        complete() {
          $(".cities-loading").hide(0);
        }
      });
    }), 500);
  }
}
