$(() => $('#customer-account-form form').validate({
  errorClass: 'invalid',
  errorPlacement(error, element) {
    if ($(element).attr('id').indexOf('customer_account_agreement_accepted') === 0) {
      const host = $('label#agreement-checkbox-label');
      error.insertAfter(host);
    } else {
      error.insertAfter(element);
    }
  },
  rules: {
    'user[password]': {
      required: true,
      minlength: 8
    },
    'user[password_confirmation]': {
      equalTo: '#user_password'
    },
    'user[email]': {
      required: true,
      email: true
    },
    'user[email_confirmation]': {
      required: true,
      equalTo: '#user_email',
      email: true
    },
    'customer_account[first_name]': {
      required: true,
      minlength: 2
    },
    'customer_account[last_name]': {
      required: true,
      minlength: 2
    },
    'customer_account[company_name]': {
      required: true,
      minlength: 2
    },
    'customer_account[billing_state]': {
      required: true,
      minlength: 2
    },
    'customer_account[billing_city]': {
      required: true
    },
    'customer_account[billing_street_address]': {
      required: true,
      minlength: 2
    },
    'customer_account[billing_zip]': {
      required: true,
      regex: '^\\d{5}(-\\d{4})?$'
    },
    'customer_account[email_addresses]': {
      regex: '^([\\w+-.%]+@[\\w-.]+\\.[A-Za-z]{2,4},? ?)+$'
    },
    'customer_account[agreement_accepted]': {
      required:  true
    },
    'customer_account[telephone_1]': {
      required: true,
      regex: '^(\\d| |-|\\+)+$'
    },
    'customer_account[telephone_2]': {
      regex: '^(\\d| |-|\\+)+$'
    },
    'customer_account[web_address]': {
      required: true,
      regex: '\\S+\\.\\S+'
    },
    'customer_account[brand_affiliation]': {
      required: true
    }
  },
  messages: {
    'customer_account[first_name]': {
      required: 'Please provide your first name.',
      minlength: 'Name should be at least 2 letters long.'
    },
    'customer_account[last_name]': {
      required: 'Please provide your last name.',
      minlength: 'Name should be at least 2 letters long.'
    },
    'customer_account[company_name]': {
      required: 'Please provide your company name.',
      minlength: 'Name should be at least 2 letters long.'
    },
    'user[email_confirmation]': {
      equalTo: 'Email addresses don\'t match.'
    },
    'user[password_confirmation]': {
      equalTo: 'Passwords don\'t match.'
    },
    'customer_account[agreement_accepted]':
      'You need to agree to our terms if you want to continue.',
    'customer_account[telephone_1]': {
      required: 'Please provide your business phone.',
      regex: 'Phone number can contain only digits, spaces, + or -'
    },
    'customer_account[telephone_2]': {
      regex: 'Phone number can contain only digits, spaces, + or -'
    },
    'customer_account[web_address]':
      'Please provide a valid website address. Direct mail does not work for companies that do not have a website.',
    'customer_account[billing_state]': {
      required: 'Please provide state.',
      minlength: 'Please provide either state code (eg. CO) or full state name'
    },
    'customer_account[billing_city]': {
      required: 'Please provide city.'
    },
    'customer_account[billing_street_address]': {
      required: 'Please provide street address.',
      minlength: 'Street address is too short'
    },
    'customer_account[billing_zip]': {
      required: 'Please provide zip code.',
      minlength: 'Supply either 5 digit zip code or ZIP+4 code',
      regex: 'Supply either 5 digit zip code or ZIP+4 code'
    },
    'customer_account[brand_affiliation]': {
      required: 'You need to choose Industry or Affiliation.'
    }
  }
}));
