/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

// * cleave.js - 1.5.2
// * https://github.com/nosir/cleave.js

$(function() {
  if (!$('#cc-num').length) { return false; }
  new Cleave('#cc-num', { creditCard: true });
});

