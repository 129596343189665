$(window).load(function() {
  // Define the functions that show & hide the elements
  const paintStreetAddress = function() {
    const street_address_is_checked = $('#search_by_radio_street_address').is(':checked');
    if (street_address_is_checked === true) {
      $( "#street_address_fields" ).show();
      $( "#zip_5_fields" ).hide();
      $( "#fips_codes_fields" ).hide();
    }
  };

  const paintZip = function() {
    const zip_5_is_checked = $('#search_by_radio_zip_5').is(':checked');
    if (zip_5_is_checked === true) {
      $( "#street_address_fields" ).hide();
      $( "#zip_5_fields" ).show();
      $( "#fips_codes_fields" ).hide();
    }
  };

  const paintFips = function() {
    const fips_codes_is_checked = $('#search_by_radio_fips_codes').is(':checked');
    if (fips_codes_is_checked === true) {
      $( "#street_address_fields" ).hide();
      $( "#zip_5_fields" ).hide();
      $( "#fips_codes_fields" ).show();
    }
  };

  // Call the functions on page load:
  paintStreetAddress();
  paintFips();
  paintZip();

  // Call the functions when the radio buttons change:
  $( "#search_by_radio_street_address").on('change', (() => paintStreetAddress()));

  $( "#search_by_radio_zip_5").on('change', (() => paintZip()));

  $( "#search_by_radio_fips_codes").on('change', (() => paintFips()));
});
