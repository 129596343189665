$(function() {
   (function($) {
     var allPanels = $('.faq-box > .faq-question-container > .faq-answer').hide();
    $('.faq-box > .faq-question-container > .faq-tab').click(function() {
      $target = $(this).nextAll(".faq-answer").eq(0);
      if (!$target.hasClass('active')) {
        $target.slideDown();
        $target.addClass('active');
        $(this).find(".question-header").addClass('faq-active-tab');
      }
      else {
        $target.slideUp();
        $target.removeClass('active');
        $(this).find(".question-header").removeClass('faq-active-tab');
      }
      return false;
    });
   })(jQuery);
 });
