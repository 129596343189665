let placeSearch;
let autocomplete;

function initAutocomplete() {
  // Create the autocomplete object, restricting the search predictions to
  // geographical location types.
  autocomplete = new google.maps.places.Autocomplete(
    document.getElementById("response_autocomplete"),
    { types: ["geocode"] }
  );
  // Avoid paying for data that you don't need by restricting the set of
  // place fields that are returned to just the address components.
  autocomplete.setFields(["address_component"]);
  // When the user selects an address from the drop-down, populate the
  // address fields in the form.
  autocomplete.addListener("place_changed", fillInAddress);
}

function fillInAddress() {
  let componentForm = {
    response_street_address: '',
    response_city: '',
    response_state: '',
    response_postal_code: ''
  };

  // Get the place details from the autocomplete object.
  const place = autocomplete.getPlace();

  for (const component in componentForm) {
    document.getElementById(component).value = "";
    document.getElementById(component).disabled = false;
  }

  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  for (const component of place.address_components) {
    const addressType = component.types[0];

    switch (addressType) {
      case 'street_number':
      case 'route':
        componentForm['response_street_address'] += `${component['long_name']} `
        break;
      case 'locality':
        componentForm['response_city'] = component['long_name']
        break;
      case 'administrative_area_level_1':
        componentForm['response_state'] = component['short_name']
        break;
      case 'postal_code':
        componentForm['response_postal_code'] = component['long_name']
        break;
    }
  }

  for (const component in componentForm) {
    document.getElementById(component).value = componentForm[component].trim();
  }
}


// Bias the autocomplete object to the user's geographical location,
// as supplied by the browser's 'navigator.geolocation' object.
function geolocate() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      const geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      const circle = new google.maps.Circle({
        center: geolocation,
        radius: position.coords.accuracy,
      });
      autocomplete.setBounds(circle.getBounds());
    });
  }
}


$(function() {
  initAutocomplete();
})
