$(function () {
  let today = new Date;
  let defaultFromDate = new Date().setDate(today.getDate() - 28)
  $('#response-datepicker-from').datetimepicker({
    format: 'YYYY-MM-DD',
    defaultDate: defaultFromDate
  });
  $('#response-datepicker-to').datetimepicker({
    format: 'YYYY-MM-DD',
    defaultDate: today
  });
  $('#response-datepicker').datetimepicker({
    format: 'YYYY-MM-DD'
  });
});

function checkResponseSource(event) {
  var sourceValue = $('input[type="radio"]:checked').val();
  if (sourceValue == 'phone_call') { $('#response-source-call').removeClass('d-none') } else $('#response-source-call').addClass('d-none');
  if (sourceValue == 'email') { $('#response-source-email').removeClass('d-none') } else $('#response-source-email').addClass('d-none');
  if (sourceValue == 'website') { $('#response-source-website').removeClass('d-none') } else $('#response-source-website').addClass('d-none');
}

$(function() {
  $("#chart-1").click(function(event) {
    chart = Chartkick.charts["chart-1"].getChartObject();
    firstPoint = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)[0];
    if(firstPoint) {
      dates = chart.data.labels[firstPoint.index].split(' ');
      dateFrom = dates[0];
      dateTo = dates[2];
      URL = window.location.href.split('?')[0].replace('responses_report', 'responses')+"?date_from="+dateFrom+"&date_to="+dateTo
      window.open(URL, '_self');
    }
  });
  if ($("#response-source-inputs")) { checkResponseSource(event) }
  $("#response-source-inputs").click(function(event) {
    checkResponseSource(event);
  });
});
