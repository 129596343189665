$(function() {
  $('#datetimepicker-date-from').datetimepicker({
    inline: true,
    format: 'MM-DD-YYYY',
    useCurrent: false,
    maxDate: Date.now(),
  });
  $('#datetimepicker-date-to').datetimepicker({
    inline: true,
    format: 'MM-DD-YYYY',
    useCurrent: false,
    maxDate: Date.now()
  });

  $('#datetimepicker-date-from').on("dp.change", function (e) {
    $('#datetimepicker-date-to').data("DateTimePicker").minDate(e.date);
  });
  $('#datetimepicker-date-to').on("dp.change", function (e) {
    $('#datetimepicker-date-from').data("DateTimePicker").maxDate(e.date);
  });

  $('#select-all-list-orders').on('click', function (e) {
    e.preventDefault()
    var list_order_checkboxes = $("input[name=list_orders\\[\\]]");
    list_order_checkboxes.prop("checked", !list_order_checkboxes.prop("checked"))

    if (list_order_checkboxes.prop("checked")) {
      $(this).text('Unselect All')
    } else {
      $(this).text('Select All')
    }
  })
})
