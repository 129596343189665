/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export default class LeadRadius {
  constructor(map, lat, lng, radius){
    this.map    = map;
    this.center = {
      lat,
      lng
    };
    this.radius = radius * 1609.34; // miles to meters

    this.strokeColor = '#FF0000';
    this.strokeOpacity = 0.8;
    this.strokeWeight = 2;
    this.fillColor = '#FF0000';
    this.fillOpacity = 0.35;
  }

  radiusSettings() {
    return {
      strokeColor: this.strokeColor,
      strokeOpacity: this.strokeOpacity,
      strokeWeight: this.strokeWeight,
      fillColor: this.fillColor,
      fillOpacity: this.fillOpacity,
      map: this.map,
      center: this.center,
      radius: this.radius
    };
  }
}

