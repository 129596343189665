/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import Cleave from "cleave.js";
import "chartkick/chart.js";

require("@rails/ujs").start();
window.$ = require('jquery');
require('jquery-validation');
// I tried to import it as a webpack module but failed,
// if you know how to do it, please fix
require('./lib/jquery.flexdatalist')

// Moved from customer_account_form_validation.js
// Also in user_form_validations.js
$.validator.addMethod('regex', (function(value, element, regexp) {
    const re = new RegExp(regexp);
    return this.optional(element) || re.test(value);
  }), 'Please check your input. Emails should be separated by \',\'');

// cannot load datatables without imports-loader lib, recommended way by:
// https://stackoverflow.com/questions/57964095/rails-6-webpack-datatables-jquery
require('imports-loader?define=>false!datatables.net')(window, $);
require('imports-loader?define=>false!datatables.net-bs')(window, $);

// Moved from datatables.js where it didn't work after switching to webpack.
// I suppose it can be done in another way, than this, but works.
$.extend( true,  $.fn.dataTable.defaults, {
    responsive: true,
    pagingType: 'full',
    //dom:
    //  "<'row'<'col-sm-4 text-left'f><'right-action col-sm-8 text-right'<'buttons'B> <'select-info'> >>" +
    //  "<'row'<'dttb col-12 px-0'tr>>" +
    //  "<'row'<'col-sm-12 table-footer'lip>>"
  });

import 'bootstrap/dist/js/bootstrap';
import moment from 'moment'
window.moment = moment

// Workaround for: customer_history_reports.js:2 Uncaught TypeError: $(...).datetimepicker is not a function
$.fn.datetimepicker = require('eonasdan-bootstrap-datetimepicker');

// ./custom
import './custom/bootstrap.js'
import './custom/counts_snapshots'
import './custom/customer_accounts'
import './custom/customer_history_reports'
import './custom/datatables'
import './custom/faq'
import './custom/footer'
import './custom/list_counts'
import './custom/responses'
import './custom/autocomplete_address'
import './custom/bulk_download_list_files'

// // ./customer_accounts
import './customer_accounts/customer_account_form_validation'
import './customer_accounts/user_agreement'

// // ./lead_count_tool
import InitLeadCount from './lead_count_tool/init.js'
import LeadMap from './lead_count_tool/lead_map.js'
import LeadToggleMarkers from './lead_count_tool/lead_toggle_markers.js'
import LeadRadius from './lead_count_tool/lead_radius.js'
import LeadForm from './lead_count_tool/lead_form.js'

window.InitLeadCount = InitLeadCount
window.LeadMap = LeadMap
window.LeadToggleMarkers = LeadToggleMarkers
window.LeadForm = LeadForm
window.LeadRadius = LeadRadius

// // ./modules
import './modules/lead_counts'

// Moved from user_form_validations.js
$.validator.addMethod('greaterThan', (function(value, element, param) {
  const $min = $(param).val();
  if (($min !== '') && (value !== '')) {
    return parseInt(value) > parseInt($min);
  } else {
    return true;
  }
}), 'Maximum must be greater than minimum');


// // ./registration
import './registration/agreement_print'
import './registration/registration_step_2'
import './registration/registration_step_3'
import './registration/registration_step_4'
import './registration/user_form_validations'

// Functions from old application.js
$(function() {
  $("a[rel~=popover], .has-popover").popover();
  return $("a[rel~=tooltip], .has-tooltip").tooltip();
});

var delay;

delay = (function() {
  var timer;
  timer = 0;
  return function(callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

window.delay = delay
