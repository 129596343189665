$(function() {
  let current = new Date();
  const maxDate = Date.now();
  const minDate = current.setDate(current.getDate() - 60);

  $('#bulk-download-list-files-from').datetimepicker({
    inline: true,
    format: 'MM-DD-YYYY',
    useCurrent: false,
    minDate: minDate,
    maxDate: maxDate,
    defaultDate: maxDate
  });
  $('#bulk-download-list-files-to').datetimepicker({
    inline: true,
    format: 'MM-DD-YYYY',
    useCurrent: false,
    defaultDate: maxDate,
    maxDate: maxDate
  });

  $('#bulk-download-list-files-from').on("dp.change", function (e) {
    $('#bulk-download-list-files-to').data("DateTimePicker").minDate(e.date);
  });
  $('#bulk-download-list-files-to').on("dp.change", function (e) {
    $('#bulk-download-list-files-from').data("DateTimePicker").maxDate(e.date);
  });
})
