/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  $('#registration-step-1').validate({
    errorClass: 'invalid',
    errorPlacement(error, element) {
      if ($(element).attr('id').indexOf('customer_account_agreement_accepted') === 0) {
        const host = $('label#agreement-checkbox-label');
        error.insertAfter(host);
      } else {
        error.insertAfter(element);
      }
    },
    rules: {
      'user[password]': {
        required: true,
        minlength: 8
      },
      'user[password_confirmation]': {
        equalTo: '#user_password'
      },
      'user[email]': {
        required: true,
        email: true
      },
      'user[email_confirmation]': {
        required: true,
        equalTo: '#user_email',
        email: true
      },
      'customer_account[first_name]': {
        required: true,
        minlength: 2
      },
      'customer_account[last_name]': {
        required: true,
        minlength: 2
      },
      'customer_account[company_name]': {
        required: true,
        minlength: 2
      },
      'customer_account[billing_state]': {
        required: true,
        minlength: 2
      },
      'customer_account[billing_street_address]': {
        required: true,
        minlength: 2
      },
      'customer_account[billing_zip]': {
        required: true,
        regex: '^\\d{5}(-\\d{4})?$'
      },
      'customer_account[billing_city]': {
        required: true
      },
      'customer_account[agreement_accepted]': {
        required: true
      },
      'customer_account[telephone_1]': {
        required: true,
        regex: '^(\\d| |-|\\+)+$'
      },
      'customer_account[telephone_2]': {
        regex: '^(\\d| |-|\\+)+$'
      },
      'customer_account[web_address]': {
        required: true,
        regex: '\\S+\\.\\S+'
      },
      'customer_account[brand_affiliation]': 'required'
    },
    messages: {
      'customer_account[first_name]': {
        required: 'Please provide your first name.',
        minlength: 'Name should be at least 2 letters long.'
      },
      'customer_account[last_name]': {
        required: 'Please provide your last name.',
        minlength: 'Name should be at least 2 letters long.'
      },
      'customer_account[company_name]': {
        required: 'Please provide your company name.',
        minlength: 'Name should be at least 2 letters long.'
      },
      'user[email_confirmation]': {
        equalTo: 'Email addresses don\'t match.'
      },
      'user[password_confirmation]': {
        equalTo: 'Passwords don\'t match.'
      },
      'customer_account[agreement_accepted]':
        'You need to agree to our terms if you want to continue.',
      'customer_account[telephone_1]': {
        required: 'Please provide your business phone.',
        regex: 'Phone number can contain only digits, spaces, + or -'
      },
      'customer_account[telephone_2]': {
        regex: 'Phone number can contain only digits, spaces, + or -'
      },
      'customer_account[web_address]':
        'Please provide a valid website address. Direct mail does not work for companies that do not have a website.',
      'customer_account[brand_affiliation]':
        'You need to choose Industry or Affiliation.',
      'customer_account[billing_state]': {
        required: 'Please provide state.',
        minlength: 'Please provide either state code (eg. CO) or full state name'
      },
      'customer_account[billing_street_address]': {
        required: 'Please provide street address.',
        minlength: 'Street address is too short'
      },
      'customer_account[billing_city]': {
        required: 'Please provide city.'
      },
      'customer_account[billing_zip]': {
        required: 'Please provide zip code.',
        minlength: 'Supply either 5 digit US zip code or ZIP+4 code',
        regex: 'Supply either 5 digit US zip code or ZIP+4 code'
      }
    }
  });

  $('#registration-step-2').validate({
    errorClass: 'invalid',
    errorPlacement(error, element) {
      let host;
      if ($(element).attr('id').indexOf('list_type_type_') === 0) {
        host = $('#type-validation-error-message');
        error.appendTo(host);
      } else if ($(element).attr('id').indexOf('list_type_option_') === 0) {
        host = $('#option-validation-error-message');
        error.appendTo(host);
      } else if ($(element).attr('id').indexOf('list_type_fips_codes') === 0) {
        host = $('#flexdatalist-error-container');
        error.appendTo(host);
        const $borderElem = $('.flexdatalist-county').parents("ul.flexdatalist-multiple");
        $borderElem.addClass('invalid');
        $('.flexdatalist-county').on('select:flexdatalist', function() {
          $borderElem.removeClass('invalid');
          host.html("");
        });
      } else {
        error.insertAfter(element);
      }
    },
    ignore: [],
    rules: {
      'list_type[type]': 'required',
      'list_type[leads_record_limit]': 'required',
      'list_type[option]': 'required',
      'list_type[delivery_email_addresses]': {
        regex: '^([\\w+-.%]+@[\\w-.]+\\.[A-Za-z]{2,4},? ?)+$'
      },
      'list_type[leads_max_asking_price]': {
        greaterThan: '#list_type_leads_min_asking_price'
      }
    },
    messages: {
      'list_type[type]': 'Please choose an option.',
      'list_type[option]': 'Please choose an option.'
    }
  });

  $('#registration-step-3 form#new_card_detail').validate({
    errorClass: 'invalid',
    rules: {
      'card_detail[number]': {
        required: true,
        minlength: 14
      },
      'card_detail[first_name]': 'required',
      'card_detail[last_name]': 'required',
      'card_detail[address1]': 'required',
      'card_detail[city]': 'required',
      'card_detail[state]': 'required',
      'card_detail[zip]': 'required',
      'customer_account[email_addresses]': {
        regex: '^([\\w+-.%]+@[\\w-.]+\\.[A-Za-z]{2,4},? ?)+$'
      }
    },
    messages: {
      'card_detail[number]': {
        minlength: 'Please enter at least 12 digits.',
        required: 'Please provide CC number.'
      },
      'card_detail[first_name]': {
        required: 'Please provide first name.'
      },
      'card_detail[last_name]': {
        required: 'Please provide last name.'
      },
      'card_detail[address1]': {
        required: 'Please provide street address.'
      },
      'card_detail[city]': {
        required: 'Please provide city.'
      },
      'card_detail[state]': {
        required: 'Please provide state.'
      },
      'card_detail[zip]': {
        required: 'Please provide zip code.'
      }
    }
  });


  $('#registration-step-4 form').validate({
    errorPlacement(error, element) {
      const host = $('.validation-error-message');
      error.appendTo(host);
    },
    errorClass: 'invalid',
    rules: {
      postcard_creation_method: {
        required: true
      }
    },
    messages: {
      'postcard_creation_method': 'Please choose an option.'
    }
  });
});
