$(function() {
  $('#counts-table').DataTable({
    "columns": [
      { "searchable": false },
      null,
      { "searchable": false },
      null,
      { "searchable": false },
      { "searchable": false },
      { "searchable": false }
    ],
    'pagingType': "simple_numbers",
    "language": {
      "searchPlaceholder": "by description and search type"
    }
  });
})
