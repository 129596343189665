$(function() {
  // Handle changing customer accounts dropdown menu title to acrtive one on click
  $('.ca-links li a').on('click', function() {
    const text = $(this).text();
    $('.ca-title').text(text);
    $('.ca-title').append(' <span class="caret"/>');
  });

  // Handle changing customer accounts dropdown menu title to acrtive one on page load
  const text = $('.ca-links > li.active').text();
  if (text !== '') {
    $('.ca-title').text(text);
    $('.ca-title').append('<span class="caret"/>');
  }
});
