/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export default class LeadToggleMarkers {
  constructor(element, leadMap){
    this.element = $(element);
    this.leadMap = leadMap;
    if (this.element.length > 0) {
      this.element.on('click', function() {
        leadMap.toogleVisibleMarkers();
        $(this).find('.glyphicon').toggleClass("glyphicon-eye-open glyphicon-eye-close");
      });
    }
  }

  reset() {
    this.element.find('.glyphicon').removeClass("glyphicon-eye-open")
                               .removeClass("glyphicon-eye-close")
                               .addClass("glyphicon-eye-open");
  }
  show() {
    this.element.removeClass('hidden');
  }
  setInitialVisibility() {
    if (this.element.find('.glyphicon').hasClass("glyphicon-eye-close")) {
      leadMap.hideMarkers();
    }
  }
}
