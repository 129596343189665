/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
//  send_file_trigger        = $('#artwork-form input[name=postcard_creation_method]')
  const browse_file_button_label = $('#artwork-form #browse-file-button span');
  const browse_file_button       = $('#artwork-form #browse-file-button input');
  const file_text_field          = $('#artwork-form input[name=file_name]');
  const file_input_field         = $('#artwork-form input[name=artwork]');
//  logo_file_field          = $('#artwork-form input#artwork_choice_logo')
//  logo_text_field          = $('#artwork-form input[name=logo_name]')
//  additional_file_field    = $('#artwork-form input#artwork_choice_additional_info')
//  additional_text_field    = $('#artwork-form input[name=additional_info_name]')
//  artwork_choice_form      = $('#artwork-form #artwork-choice input')
//  artwork_select_template  = $('#artwork-form #artwork-choice select')

  const enable_file_button = function() {
    $(browse_file_button_label).removeClass('disabled');
    $(browse_file_button).removeAttr('disabled');
  };

//  disable_file_button = ->
//    $(browse_file_button_label).addClass('disabled')
//    $(browse_file_button).attr('disabled', true)
//
//  enable_artwork_choice_form = ->
//    $(artwork_select_template).removeClass('disabled')
//    $(artwork_select_template).removeAttr('disabled')
//    for field in artwork_choice_form
//      $(field).removeClass('disabled')
//      $(field).removeAttr('disabled')
//
//  disable_artwork_choice_form = ->
//    $(artwork_select_template).addClass('disabled')
//    $(artwork_select_template).attr('disabled', true)
//    for field in artwork_choice_form
//      $(field).addClass('disabled')
//      $(field).attr('disabled', true)

  enable_file_button();

//  if send_file_trigger.val() == 'own_artwork'
//    enable_file_button()
//    disable_artwork_choice_form()
//  else
//    disable_file_button()
//    enable_artwork_choice_form()

//  send_file_trigger.change ->
//    switch this.value
//      when 'own_artwork'
//        enable_file_button()
//        disable_artwork_choice_form()
//      else
//        disable_file_button()
//        enable_artwork_choice_form()

  file_input_field.on('change', function() {
    const full_path = this.value;
    const filename = full_path.replace(/^.*[\\\/]/, '');
    file_text_field.val(filename);
  });
});

//  logo_file_field.change ->
//    full_path = this.value
//    filename = full_path.replace(/^.*[\\\/]/, '')
//    logo_text_field.val(filename)
//
//  additional_file_field.change ->
//    full_path = this.value
//    filename = full_path.replace(/^.*[\\\/]/, '')
//    additional_text_field.val(filename)
