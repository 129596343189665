/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Math.roundTo = (number, precision) => Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision);

const price = {
  pm_postcards_4x6: 0.90,
  pm_postcards_6x11: 1.25,
  pm_postcards_5x8: 1.15,
  pm_list_only: 0.30,
  pmal_postcards_4x6: 1.68,
  pmal_postcards_6x11: 2.38,
  pmal_list_only: 0.5
};
  // al_postcards_4x6: 0.84
  // al_postcards_6x11: 1.19
  // al_list_only: 0.25

let unit_price = 0;
let count = 100;

const update_sum = function() {
  const sum = Math.roundTo(count * unit_price, 2);
  const sum_text = '$' + sum + ' <small>per&nbsp;week</small>';
  $('#list-order-price').html(sum_text);
};

const check_prices = function() {
  const list_type = $('input[name="list_type[type]"]:checked').val();
  const total_count = $('#list_type_leads_record_limit').val();
  if ((list_type !== undefined) && (total_count !== undefined)) {
    unit_price = price[list_type];
    count = total_count;
    update_sum();
  }
};

const show_minimum_price = function() {
  const list_type = $('input[name="list_type[type]"]:checked').val();
  if (list_type === 'pm_list_only') {
    $('#minimum-price').text('No minimums.');
  } else {
    $('#minimum-price').text('Minimum Record Limit of 200 Postcards per week.');
  }
};

const showLeadSearchOption = function(value) {
  //hide map overlay text
  $('.no-leads-div').css("display", "none");
  if (value === "mile_radius") { $('#option-mile-radius').show(); } else { $('#option-mile-radius').hide(); }
  if (value === "zip_codes") { $('#option-zip-codes').show(); } else { $('#option-zip-codes').hide(); }
  if (value === "counties") { $('#option-counties').show(); } else { $('#option-counties').hide(); }
  if (window.leadMap) {
    window.leadMap.removeAllMarkers();
    window.leadMap.removeAllRadii();
    window.leadMap.removeAllPolygons();
  }
};

$(function() {
  // hide loading message on start
  $(".cities-loading").hide();

  // disable scroll in number_field_tag fields
  $('form#registration-step-2').on('focus', 'input[type=number]', function(e) {
    $(this).on('mousewheel.disableScroll', function(e) {
      e.preventDefault();
    });
  });

  $('form#registration-step-2').on('blur', 'input[type=number]', function(e) {
    $(this).off('mousewheel.disableScroll');
  });

  // calc total price of choosen list order
  $('input[name="list_type[type]"]').on('change', function() {
    const weekly_record_limit = $('#list_type_leads_record_limit');

    if ($(this).attr('id') === 'list_type_type_pm_list_only') {
      weekly_record_limit.attr({
        "min": "50",
        "max": "500",
        "value": "50"
      });
      weekly_record_limit.val('50');
      count = 50;
    } else {
      weekly_record_limit.attr({
          "min": "200",
          "max": "500",
          "value": "200"
        });
      weekly_record_limit.val('200');
      count = 200;
    }

    unit_price = price[this.value];
    update_sum();
  });

  $('#list_type_leads_record_limit').on('keyup mouseup change', function() {
    $(this).attr({
      "value": this.value
    });
    count = this.value;
    update_sum();
  });

  // toggle visibility of wanted list order criteria
  $('input[name="list_type[option]"]').on('change', function() {
    showLeadSearchOption(this.value);
  });

  // toggle visibility of minimum price
  $('input[name="list_type[type]"]').on('change', (() => show_minimum_price()));


  // disable submit button after click
  $('form#registration-step-2').on('submit', function(e) {
    $('input[type="submit"]').attr('disabled', true);
    setTimeout((function() {
      $('input[type="submit"]').attr('disabled', false);
    }), 2000);
  });

  check_prices();
  update_sum();
  showLeadSearchOption($('input[name="list_type[option]"]:checked').val());
});
