/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export default class LeadForm {
  constructor(elem){
    this.elem = elem;
    this.api  = window.leadApiUrl;
    this.api_token = window.leadApiToken;
    this.limit = undefined;
    this.fipsCodeInput = null;
    this.zipCodeInput = null;
    this.spinner      = null;
    this.activeSubmit = () => true;
    this.fillIfParametersGiven();
  }

  bindSubmit(btnElementSelector, activeCondition){
    if (typeof activeCondition === "function") {
      this.activeSubmit = activeCondition;
    }

    const self = this;
    if (btnElementSelector !== undefined) {
      $(btnElementSelector).on('click', event => self.submitForm());

    } else {
      $('#submit-btn').on('click', event => self.submitForm());
    }
  }

  submitForm(activeCondition){
    if (!this.activeSubmit.call()) { return; }
    const self = this;
    window.leadMap.hideOverlayText();
    if (this.spinner) { this.spinner.show(); }

    $.ajax({
      url: this.api,
      type: 'post',
      data: self.buildData(),
      headers: {
        "Authorization": this.api_token
      },
      success(data, textStatus) {
        self.bindSaveLeads(data);
        self.success(data, textStatus);
      }}).done(() => self.done()).error(function(event){
      $('#save-button').addClass('hidden');
      self.error(event);
    });
  }
    // .fail (event)->
    //   self.failure(event)

  buildData() {
    let data = "";
    if (this.elem.find('#lead-street-address').length > 0) {
      data = data + "&street_address=" + this.elem.find('#lead-street-address')[0].value;
    }

    if (this.elem.find('#lead-city').length > 0) {
      data = data + "&city=" + this.elem.find('#lead-city')[0].value;
    }

    if (this.elem.find('#lead-state').length > 0) {
      data = data + "&state=" + this.elem.find('#lead-state')[0].value;
    }

    if (this.elem.find('#lead-zip').length > 0) {
      data = data + "&zip=" + this.elem.find('#lead-zip')[0].value;
    }

    if (this.elem.find('#zipcode-input').length > 0) {
      data = data + "&zip_codes=" + this.elem.find('#zipcode-input')[0].value;
    }

    if (this.elem.find('.flexdatalist-county').length > 0) {
      data = data + "&fips_codes=" + this.elem.find('.flexdatalist-county')[0].value;
    }

    if (this.elem.find('.search-type').length > 0) {
      data = data + "&search_type=" + this.elem.find('.search-type')[0].value;
    }

    if (this.limit !== undefined) {
      data = data + "&limit=" + this.limit;
    }

    if (this.elem.find('#distance-input').length > 0) {
      data = data + "&distance=" + this.elem.find('#distance-input')[0].value;
    }

    if ($('.hide-leads-checkbox').length > 0) {
      data = data + "&hide_leads=" + $('.hide-leads-checkbox').is(':checked');
    }

    if ($('#list_type_leads_max_asking_price').length >0) {
      data = data + "&max_asking_price=" + $('#list_type_leads_max_asking_price')[0].value;
    }

    if ($('#list_type_leads_min_asking_price').length >0) {
      data = data + "&min_asking_price=" + $('#list_type_leads_min_asking_price')[0].value;
    }

    if ($('#min-asking-price').length >0) {
      data = data + "&min_asking_price=" + $('#min-asking-price')[0].value;
    }

    if ($('#max-asking-price').length >0) {
      data = data + "&max_asking_price=" + $('#max-asking-price')[0].value;
    }
    return data;
  }

  fillIfParametersGiven() {
    if ($('#save-count-modal').length === 0) { return; }

    const url = new URL(window.location.href);
    const search_type =  url.searchParams.get('search_type');
    switch (search_type) {
      case 'zip_codes':
        $('#zipcode-search').trigger('click');
        this.elem.find('#zipcode-input').val(url.searchParams.get('zip_codes'));
        break;
      case 'address':
        $('#address-search').trigger('click');
        this.elem.find('#lead-street-address').val(url.searchParams.get('street_address'));
        this.elem.find('#lead-city').val(url.searchParams.get('city'));
        this.elem.find('#lead-state').val(url.searchParams.get('state'));
        this.elem.find('#lead-zip').val(url.searchParams.get('zip'));
        var distance = url.searchParams.get('distance') || 30;
        this.elem.find('#distance-input').val(distance);
        break;
      case 'fips_codes':
        $('#fips-search').trigger('click');
        this.elem.find('.flexdatalist-county').val(url.searchParams.get('fips_codes'));
        this.elem.find('input.flexdatalist-state').val(url.searchParams.get('states'));
        break;
    }

    $('#min-asking-price').val(url.searchParams.get('min_asking_price'));
    $('#max-asking-price').val(url.searchParams.get('max_asking_price'));
  }

  bindSaveLeads(response) {
    const self = this;
    $('#save-button').removeClass('hidden');
    //@request = self.objectifyForm(self.buildData)
    this.request = response['meta']['request'];
    this.response = response['meta']['per_zipcode'];
    this.total = response['meta']['total'];

    $('#request-hidden').val(JSON.stringify(this.request));
    $('#response-hidden').val(JSON.stringify(this.response));

    $('#request').val(self.sanitizeRequest(this.request));
    $('#response').val(self.sanitizeResponse(this.response));
  }

  sanitizeRequest(request) {
    const self = this;
    const keys = Object.keys(request);
    let text = JSON.stringify(request, null, 1);
    text = text.replace(/({\n|\n})/gi, '');
    return text;
  }

  sanitizeResponse(response) {
    const self = this;
    let text = '';
    for (let propValue of Array.from(response)) {
      text += `${propValue['state']} ${propValue['zip_code']}: ${propValue['count']} leads\n`;
    }
    return text;
  }

  success(data, textStatus) {
    let lat, lng;
    const {
      leadMap
    } = window;
    leadMap.removeAllMarkers();
    leadMap.removeAllPolygons();

    for (let lead of Array.from(data['leads'])) {
      lat = parseFloat(lead['latitude']);
      lng = parseFloat(lead['longitude']);
      leadMap.addMarker(lat, lng);
    }

    const center   = data['meta']['center'];
    const distance = data['meta']['distance'];
    const southWest = data['meta']['south_west'];
    const northEast = data['meta']['north_east'];

    if (distance) {
      const circle = new LeadRadius(leadMap.map, center.lat, center.lng, distance);
      leadMap.drawRadius(circle);
    } else {
      leadMap.removeAllRadii();
      leadMap.updateZoom(southWest, northEast);
    }

    if (data['meta']['search_type'] === "zip_codes") {
      const zipCodes = LeadForm.sanitizeZipCodes(this.zipCodeInput.val());
      LeadForm.getZipGeometryAndDraw(zipCodes, window.leadMap);
    } else if (data['meta']['search_type'] === "fips_codes") {
      window.leadMap.requestAndDrawCounties(this.fipsCodeInput.val(), "keepRadii");
    }

    $('.alert-container .alert').hide();
    leadMap.initTable(data['meta']['total']);

    if (data['meta']['total'] === 0) {
      //display overlay text
      $('.no-leads-div').css("display", "flex");
    }
  }

  done() {
    $('.alert.alert-info').hide();
    if (this.spinner) { this.spinner.hide(); }
  }

  // failure: (event)->
  //   window.leadMap.removeAllMarkers()
  //   window.leadMap.removeAllRadii()
  //   console.log 'failure', event
  //   $('.alert-container .alert').switchClass('alert-info', 'alert-error')
  //   $('.alert-container .alert span').text(event.responseJSON['errors'])
  //   $('.alert-container .alert').show()
  //   leadMap.initTable(0)
  //   @spinner.hide() if @spinner

  error(event){
    let alertLevel, errorMessage;
    window.leadMap.removeAllMarkers();
    window.leadMap.removeAllRadii();

    if (event.status === 404) {
      alertLevel = 'alert-info';
      errorMessage = event.responseJSON['errors'];
    } else if (event.status === 422) {
      alertLevel = 'alert-error';
      errorMessage = event.responseJSON['errors'];
    } else if (event.status === 500) {
      alertLevel = 'alert-error';
      errorMessage = "Server error. We have been notified. We apologize for the inconvenience";
    } else {
      alertLevel = 'alert-info';
      errorMessage = event.responseJSON['errors'];
    }

    $('.alert-container .alert').removeClass('alert-info').removeClass('alert-error');
    $('.alert-container .alert').addClass(alertLevel);
    $('.alert-container .alert span').text(errorMessage);
    $('.alert-container .alert').show();
    leadMap.initTable(0);
    if (this.spinner) { this.spinner.hide(); }
    if (event.responseJSON['errors'][0] === "Record not found with error: ActiveRecord::RecordNotFound") {
      //display overlay text
      $('.no-leads-div').css("display", "flex");
    }
  }

  static getZipGeometryAndDraw(zipCodes, leadMap){
    $.ajax({
      url: '/api/v2/zip_codes_with_geometry?zips=' + zipCodes,
      type: 'get',
      headers: {
        "Authorization": this.api_token
      },
      success(data, textStatus) {
        leadMap.removeAllRadii();
        leadMap.drawZipCodes(LeadForm.sanitizeZipsGeometry(data));
        leadMap.hideOverlayText();
      }}).error(event => console.log(event));
  }

  static sanitizeZipCodes(values){
    const regexp = /(\d{5})/g;
    const matches_array = values.match(regexp);
    return matches_array;
  }

  static sanitizeFIPSCodes(values){
    const regexp = /(\d{5})/g;
    const matches_array = values.match(regexp);
    return matches_array;
  }

  static sanitizeCountiesGeometry(countiesData){
    const countiesGeometry = [];

    if (countiesData) {
      for (let countyData of Array.from(countiesData)) {
        countiesGeometry.push(JSON.parse(countyData.geometry));
      }
    }

    return countiesGeometry;
  }

  static sanitizeZipsGeometry(zipsData){
    const zipsGeometry = [];

    if (zipsData) {
      for (let zipData of Array.from(zipsData)) {
        const geometry = JSON.parse(zipData.geometry);
        if (geometry) {
          zipsGeometry.push(geometry);
        }
      }
    }

    return zipsGeometry;
  }
}
